import React, { Suspense, lazy } from 'react';
import './Home.css'
import ServiceComp from './Components/ServiceComp';
import TechComp from './Components/TechComp';
import Footer from './Components/Footer';
import Customers from './Components/Customers';
import ContactSidebar from './ContactSidebar';
import Testimonial from './testimonials';
import HomeCarousel from './Components/HomeCarousel';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PopUpModal1 from './Modal/popUpButton';
import Faq from '../Home/faq';
import { Helmet } from 'react-helmet';

const LandingCarousel = lazy(() => import('./Carousel/LandingCarousel'))
function Home() {
  return (
    <div className="home">
      <Helmet>
        <title>Home</title>
        <meta name="description" content="Leading web development company in Pune, 
        offering expert services in website and app development, 
        e-commerce solutions, and innovative web design." />
        <meta name="keyword" content="web development company in Pune, website development company in India, best website development company in India, e-commerce web development company"/>
      </Helmet>
      <Suspense fallback={
        <div className='carousel_fallback'>
          <span className='callback_msg'>welcome...</span>
        </div>
      }>
        <PopUpModal1 />
        <LandingCarousel />
      </Suspense>
      <ContactSidebar />
      <ServiceComp />
      <HomeCarousel />
      <TechComp />
      <Customers />
      <Testimonial />
      <Faq />
      <Footer />
    </div>
  )
}

export default Home